<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("idp.learning_plan_tracking") }}
      <!-- 学习计划跟踪 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('idp.completion_status')"
        :dataSource="completionStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-select
              allowClear
              show-search
              v-model:value="planYear"
              :placeholder="$t('PleaseSelect') + $t('idp.plan_year')"
              @change="onSearch"
              style="width: 173px; margin-right: 16px"
            >
              <a-select-option
                v-for="year in yearOpts"
                :key="year"
                :value="year"
                >{{ year }}</a-select-option
              >
            </a-select>
            <a-input-search
              v-model:value="keyword"
              :placeholder="$t('PleaseEnter') + $t('idp.name_account')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="list-type-wrap">
      <ListType :checked="listType" @change="(type) => (listType = type)" />
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div
            class="content"
            :class="{ card: listType == 'card', list: listType == 'list' }"
          >
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <IdpCard
                :listType="listType"
                :dataSource="item"
                :isReview="true"
                @toResult="$refs.resultRef.open(item)"
              />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>

    <resultModal ref="resultRef" />
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

import { idpList } from "@/api/idp";

import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import IdpCard from "@/components/new/mine/IdpCard.vue";
import resultModal from "./drawer/result.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    IdpCard,
    resultModal,
    renderVNode,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      completionStatus: [
        { id: 1, name: proxy.$t("idp.completed") }, // 已完成
        { id: 2, name: proxy.$t("idp.incomplete") }, // 未完成
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      status: 0,
      keyword: "",
      planYear: null,
      listType: "list",
      listLoading: true,
      yearOpts: [],
    });

    for (let i = 0; i < 16; i++) {
      state.yearOpts.push(2024 - 5 + i);
    }

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      idpList({
        page: state.currentPage,
        pageSize: 15,
        searchType: 2,
        status: state.status,
        keyword: state.keyword,
        planYear: state.planYear,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      ...toRefs(state),
      switchStatus,
      pageChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
::v-deep(.filter) {
  .right-area {
    width: 420px;
  }
}
</style>
